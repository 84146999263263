/* Detail Styles */

.results-info {
    margin: 0;
    padding: 30px 0;
    color: #666666;
    font-size: 16px;
    font-weight: normal;
}
.results {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    margin: auto;
    margin-left: 0em;
    margin-right: 0em;
}
span.document_pdf_link {
    cursor: pointer;
}
span.document_pdf_link:hover {
    color: #0a024a;
}
.results .result {
    width: 10rem;
    max-height: 18rem;
}
/*=============*/
.result_not_found {
    text-align: center;
    padding-top: 100px;
}

p.results-info span {
    display: block;
}

p.results-info span.not_topheading {
    color: #0a0248;
    font-size: 24px;
    letter-spacing: 1px;
    line-height: normal;
    padding-bottom: 9px;
}

p.results-info span.not_botton_heading {
    color: #666666;
    font-weight: 300;
    font-size: 18px;
    line-height: normal;
}

.result-logo {
    padding-bottom: 20px;
}


/*=========*/
.result {
    width: 250px;
    padding: 16px;
    text-align: center;
    border: 1px solid #eee;
    box-shadow: 0 2px 3px #ccc;
    margin: 10px;
    margin-bottom: 3px;
    padding-bottom: 3px;
    box-sizing: border-box;
    cursor: pointer;
}

.result:hover,
.result:active {
    background-color: #C0DDF5;
}



h2.MuiTypography-root.MuiTypography-h6 {
    text-align: center;
}

.document-body {
    width: 100%;
    background: #ededed;
    padding: 15px;
    border-radius: 5px;
    height: 300px;
    overflow-y: scroll;
    color: #333333;
    margin: 10px 0;
    width: 532px;
}

.popup-content-item-outer h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
}

.popup-content-item-outer {
    font-size: 14px;
    margin-bottom: 0;
}

.popup-content-item-outer {
    margin-bottom: 15px;
}
.document-body-items p {
    margin-bottom: 15px;
}
.loader-outer {
    padding: 15% 0;
    background: #ffffffb5;
    z-index: 9;
    height: 100%;
}

.footer-right-sec p a {
    margin-left: 10px;
}

.footer-right-sec p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
}