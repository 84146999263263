/* Application styles */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
.app {
    background-color: #fff;
    padding: 0;
    min-width: 375px;
}
body {
    font-family: 'Open Sans', sans-serif;
}
.main--home {
    min-height: 40em;
}
.main--details {
    min-height: 40em;
}
.relevance_score ul {
    padding: 0;
    margin: 0;
}
.relevance_score ul li:first-child {
    padding-right: 10px;
}
.relevance_score ul li {
    list-style: none;
    display: inline-block;
}
.relevance_score ul li:first-child::after {
    content: '|';
    margin-left: 10px;
}
.document-listing h4 {
    font-size: 24px;
    color: #0a024a;
    font-weight: normal;
}

.document-listing a {
    color: #333333;
}
.document-listing a:hover{
    text-decoration: none;
}
.document-listing  a.pdf-detail{
    color: #0a024a;
}
img.document-pdf {
    margin-top: -3px;
}
.col-xs-12.col-sm-12.mb-5.result_inner:last-child {
    margin-bottom: 0 !important;
}
.container-fluid.app {
    min-height: 767px;
    position: relative;
}
.loader-outer  {
    padding: 15% 0;
}
nav.pager {
    margin-bottom: 200px;
}

.result_inner h5 {
    font-size: 1.25rem;
    line-height: 26px;
    color: #0a024a;
    font-weight: 600;
    font-family: "Open Sans";
}

.result_inner h5 span:hover {
    color: #27588a;
}
.result_inner a {
    color: #333;
    text-decoration: none;
}
.result_inner a:hover {
    color: #0a024a;
}
.result_inner .pdf-detail {
    cursor: pointer;
}
.popup-content-item-outer h4 {
    color: #0a024a;
    font-weight: 400;
}
.document-body {
    word-break: break-all;
    white-space: pre-wrap
}
h2.MuiTypography-root.MuiTypography-h6 span {
    cursor: pointer;
}
h2.MuiTypography-root.MuiTypography-h6 {
    color: #666766;
}

.advance-search-page .advance-search.text-sm-right.text-center {
    display: none;
}
.mark, mark {
    background-color: yellow;
    padding: 0 2px;
}

a, input, textarea { 
	-webkit-transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
	-moz-transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
	-ms-transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
	-o-transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
	transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
}

ul.pagination li span {
    display: block;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
}

ul.pagination li span::after {
    top: 15px !important;
}
ul.pagination.item .next_class:after {
    right: -5px;
}

ul.pagination.item li .preiview_class:after {
    left: -5px;
}

ul.pagination.item button.next_class {
    background: #ffff;
}

@media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1200px !important;
    }
}

@media (max-width: 1024px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 100% !important;
    }
}



@media (max-width:900px){
    .navbar-brand img {
        width: 100%;
    }
    .top-header .suggestions + .input-group-btn button {
        padding: 0;
        height: 38px;
    }
    .top-header img.search-icon {
        width: 30px;
        height: 20px;
    }
    .top-header .form-control {
        height: 40px;
    }
    .top-header .input-group-btn {
        margin-left: -1px;
    }
    .top-header .input-group {
        width: 100%;
        margin: 0 auto;
    }
    
}
@media (min-width: 768px) and (max-width:900px) {
    .container-fluid.app {
        min-height: 100vh;
        position: relative;
    }
}
@media (min-width: 576px) and (max-width:767px) {
    .advance-search a {
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        display: inline-flex;
        align-items: center;
    }
    .nrc-search .input-group {
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-wrap: nowrap;
    }
}
@media (max-width:575px) {
    .popup-content-item-outer {
        margin-bottom: 15px;
        width: 100%;
        max-width: 100%;
        flex: 100%;
        word-break: break-all;
        white-space: pre-wrap;
    }
    .document-body {
        width: 100%;
    }
    .result_inner a {
        word-break: break-all;
    }
    .top-header .input-group {
        width: max-content;
        margin: 5px auto;
        padding-left: 4px;
    }
    .advance-search.text-sm-right.text-center {
        margin-bottom: 20px;
        margin-top: 5px;
    }
    .row.home-search.nrc-search.m-0 img.logo.pb-5 {
        width: 200px;
    }
    .nrc-search .input-group {
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-wrap: nowrap;
    }
    .result_inner h4 {
        font-size: 18px;
        line-height: 26px;
    }
    body {
        font-size: 14px;
    }
}

@media (min-width:1600px) {
    .container-fluid.app {
        min-height: 100vh;
    }
}