.item {
    margin: 1em auto;
}
ul.pagination.item li .page-link {
    color: #666666 !important;
    font-size: 18px;
}
ul.pagination.item li.page-item.active .page-link {
    color: #0a024a !important;
} 
.pager {
    margin: 0;
    max-width: fit-content;
    padding-left: 10px;
}

/* ===================== */
ul.pagination.item .next_class {
    position: relative;
}
ul.pagination.item .next_class:after {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    border-right: 2px solid #3e3e3e;
    border-top: 2px solid #3e3e3e;
    transform: rotate( 
45deg);
    top: -2px;
    right: 5px;
}   
ul.pagination.item li .preiview_class {
    position: relative;
    width: 0px !important;
    height: 37px;
    border: 0;
    padding: 0;
}
ul.pagination.item li .preiview_class:after {
    content: "";
    position: absolute;
    border-left: 2px solid #666666;
    border-top: 2px solid #666;
    width: 8px;
    height: 8px;
    left: -10px;
    top: 15px;
    transform: rotate( 
-42deg);
} 

ul.pagination.item button.next_class {
    border: 0;
    outline: none;
}
ul.pagination.item li button:after {
    border-color: #666666 !important;
}
ul.pagination.item li .page-link {
    border: 0;
}
ul.pagination.item li.page-item.active .page-link {
    background: transparent;
    font-weight: bold;
}

ul.pagination.item li span:hover {
    background: transparent;
}
ul.pagination.item li .page-link:hover {
    background: transparent;
}
ul.pagination.item {
    margin: 70px 0;
}