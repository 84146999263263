.sui-layout-header {
    background-color: #0078d7;
    color: #eee;
}
.sui-search-box__submit {
    background: linear-gradient(rgb(60, 226, 102), rgb(34, 151, 57));
    letter-spacing: 0.1em;
}
.sui-search-box__submit:hover {
    background: linear-gradient(rgb(34, 151, 57), rgb(60, 226, 102));
}

.pager-style {
    margin-left: auto;
    margin-right: auto;
    max-width: fit-content;
}

.search-bar {
    margin: 1em;
    margin-bottom: 1em;
    margin-top: 2em;
}

.select-input .MuiFormControl-root {
    width: 100%;
}
.select-input .MuiFormControl-root .MuiInputBase-root {
    width: 100%;
}
.select-input .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
    opacity: 0;
}
.height-window {
    height: 190px;
}
.FindDocumentsInput .MuiFormControl-root {
    width: 100%;
}

h5{
    color:#0a024a;
    font-weight: 600;
}
button.MuiButtonBase-root {
    width: 200px;
    border-radius: 50px;
}
.selectAdvancedField label {
    margin-bottom: 0;
}
.MuiFormControlLabel-root .PrivateSwitchBase-root-1 {
    padding: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    margin-right: 5px;
}
h2.advance-search {
    font-size: 30px;
    color: #666666;
}
.selectAdvancedField h5 {
    font-size: 21px;
    color: #0a024a;
}
h5.find-document {
    font-size: 21px;
    color: #0a024a;
}
.FindDocumentsInput .col-lg-4.text-right.pt-3 {
    font-size: 16px;
    color: #333;
    padding-top: 10px !important;
}
.document-date-sec{
    font-size: 16px;
    padding-top: 10px !important;
}
 
.MuiOutlinedInput-input {
    padding: 12.5px 14px !important;
}
.select-input label {
    line-height: 6px !important;
    font-size: 16px;
    color: #333333;
}
button.search-btn{
    width: 280px;
    padding: 7px;
    background-color: #0a024a;
    text-transform: capitalize;
    font-size: 16px;
}
.MuiInputBase-input.MuiOutlinedInput-input { 
    font-size: 16px;
    color: #333333;
}
span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    font-size: 16px;
    font-weight: normal !important;
    font-family: 'Open Sans', sans-serif;
    color: #333;
}
#Adsearch .MuiSelect-outlined.MuiSelect-outlined { 
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
}
.advance-search-page button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.search-btn:hover {
    outline: none;
    border: none;
    box-shadow: none;
}
span.ad-date-reset-btn {
    position: absolute;
    top: 9px;
    right: 42px;
    cursor: pointer;
}

span.ad-date-reset-btn.ex-space {
    margin-right: 15px;
}
.selectAdvancedField h5.adv-check-title label span.MuiFormControlLabel-label {
    font-size: 21px !important;
    color: #0a024a !important;
}
button.search-btn[type="button"] {
    background: #ccc;
    color: #000;
    margin-left: 15px;
    transition: all 0.5s;
    outline: none;
}
button.search-btn[type="button"]:hover {
    background:#303f9f;
    color: #fff;
 }


.advanced-search-popup .MuiDialog-paperWidthSm {
    max-width: 1160px;
}
.advanced-search-popup .MuiTypography-h6 {
    font-size: 1.65rem;
}
.advanced-search-popup .selectAdvancedField h5.adv-check-title label span.MuiFormControlLabel-label {
    font-size: 19px !important;
}
.advanced-search-popup .selectAdvancedField h5 {
    font-size: 19px;
    margin-bottom: 0;
    font-weight: 500 !important;
}
.advanced-search-popup .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    font-size: 15px;
}
.advanced-search-popup .PrivateSwitchBase-root-5 {
    padding: 5px;
}
.advanced-search-popup .MuiInputBase-input.MuiOutlinedInput-input {
    font-size: 14px;
    color: #333333;
    padding: 10px 10px !important;
    line-height: 16px;
    font-weight: normal;
}
.advanced-search-popup  button.search-btn[type="button"] {
    background: #0a024a;
    color: #fff;
}
.ad-search-opt-btn.btn.btn-info {
    padding: 7px 20px;
    background-color: #0a024a;
    text-transform: capitalize;
    font-size: 16px;
    border-color: #0a024a;
    border-radius: 50px;
}
.ad-search-opt-btn.btn.btn-info:hover {
    background-color: #303f9f;
    border-color: #303f9f;
}


@media screen and (max-width: 1024px) {
    span.ad-date-reset-btn {
        top: 10px;
        right: 50px;

    }
    span.ad-date-reset-btn.ex-space {
        margin-right: 15px;
    }
  }
  @media screen and (max-width: 991px) {
    h5.find-document.my-5 {
        margin: 30px 0 !important;
    }
    .FindDocumentsInput .col-lg-4.text-right.pt-3 {
        text-align: left !important;
        padding-top: 0 !important;
        margin-bottom: 10px;
    }
    h2.advance-search {
        font-size: 30px;
        margin: 30px 0 !important;
    }
    .MuiFormControlLabel-root .PrivateSwitchBase-root-1 {
        margin-left: 4px;
    }
    .col-sm-6.select-input {
        padding-left: 0;
    }
    .select-extracted-field, .select-input {
        margin-top: 20px;
    }
    .text-right.document-date-sec {
        text-align: left !important;
        margin-bottom: 15px;
    }
    .row.mt-5.search-row {
        margin-top: 30px !important;
        margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    .selectAdvancedField h5 {
        font-size: 20px;
        color: #0a024a;
    }
    h5.find-document.my-5 {
        margin: 20px 0 !important;
        font-size: 20px;
    }
    span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
        font-size: 16px;
    }
    .FindDocumentsInput .col-lg-4.text-right.pt-3 {
        margin-bottom: 10px;
        font-size: 16px;
    }
    .text-right.document-date-sec {
        margin-bottom: 10px;
        font-size: 16px;
    }
    .MuiInputBase-input.MuiOutlinedInput-input {
        font-size: 16px;
        color: #333333;
    }
    .select-input label {
        font-size: 16px;
    }
    h2.advance-search {
        font-size: 25px;
        margin: 20px 0 !important;
    }
    span.document_pdf_link {
        cursor: pointer;
        display: flex;
        white-space: pre-wrap;
        word-break: break-all;
        align-items: flex-start;
    }
    span.document_pdf_link img {
        margin-top: 3px;
    }
}
@media screen and (max-width: 580px) {
    .col-sm-6.select-input {
        padding-left: 15px;
    }
    .row.mb-3.FindDocumentsInput {
        margin-bottom: 10px !important;
    }
    .extra-section {
        margin: 10px 0 !important;
    }
    button.search-btn {
        width: 200px;
    }
    .row.mt-5.search-row {
        margin-bottom: 40px !important;
    }
    .selectAdvancedField .row-cols-2>* {
        max-width: 100%;
        flex: 0 0 100%;
    }
    span.ad-date-reset-btn {
        top: 10px;
        right: 60px;

    }
    span.ad-date-reset-btn.ex-space {
        margin-right: 0;
    }
    button.search-btn[type="button"] {
        margin-top: 15px;
        margin-left: 0;
    }
    .ad-find-search .col-sm-6 {
        text-align: center !important;
        margin-bottom: 15px;
    }
}
