.home-search {
    margin: 0;
    max-width: 100%;
    display: block !important;
}
.logo {
    display:block;
    margin: auto auto 0;
}
.main--home {
    min-height: auto !important;
}
.nrc-search .input-group {
    width: 60%;
    margin: 0 auto;
}
a, input, textarea { 
	-webkit-transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
	-moz-transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
	-ms-transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
	-o-transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
	transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
}
.nrc-search .form-control {
    border: 1px solid #c4c4c4;
    border-radius: 50px !important;
    height: 60px;
    padding: .375rem 1.75rem;
    -webkit-transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
	-moz-transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
	-ms-transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
	-o-transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
	transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
    padding-right: 75px;
    filter: drop-shadow(0 0 8px rgba(102,102,102,0.16));
}

.nrc-search .suggestions{
    width: 100%;
}
.nrc-search .btn-primary:hover {
    background-color: transparent !important;
    border-color: transparent !important;
}
.nrc-search .btn-primary {
    background-color: transparent !important;
    border-color: transparent !important;
}
.nrc-search .input-group-btn {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 99;
}
.nrc-search  img.search-icon {
    width: 30px;
    height: 44px;
}
.nrc-search .input-group .form-control:focus, .nrc-search .input-group .form-control:hover {
    box-shadow: none;
    border: 2px solid #27588a;
}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #27588a !important;
    border-width: 2px;
}
.selectAdvancedField label:hover .MuiTypography-root, .selectAdvancedField label:hover .MuiSvgIcon-root {
    color: #27588a !important;
    fill: #27588a;
}
.nrc-search .btn-primary:not(:disabled):not(.disabled):active {
    background-color: transparent !important;
    border-color:transparent !important;
}
.nrc-search .btn-primary.focus, .nrc-search .btn-primary:focus{
    box-shadow: none !important;
}

.poweredby {
    text-align: center;
}
span.top-border {
    height: 7px;
    display: block;
    background-image: linear-gradient(to right,#6776de 0%,#04266b 100%);
}
.home-search.nrc-search {
    padding: 70px 0;
}
.top-header .suggestions{
    width: 100%;
}
.top-header .input-group {
    width: 80%;
    margin: 0 auto;
}
.top-header .form-control {
    border: 1px solid #c4c4c4;
    border-radius: 50px !important;
    height: 60px;
    -webkit-transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
	-moz-transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
	-ms-transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
	-o-transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
	transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
    padding: .375rem 1.75rem;
    padding-right: 70px;
    filter: drop-shadow(0 0 8px rgba(102,102,102,0.16));
}
.top-header .btn-primary:hover {
    background-color: transparent !important;
    border-color: transparent !important;
}
.top-header .btn-primary {
    background-color: transparent !important;
    border-color: transparent !important;
}
.top-header .input-group-btn {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 99;
}
.top-header  img.search-icon {
    width: 30px;
    height: 44px;
}
.top-header .input-group .form-control:focus, .top-header .input-group .form-control:hover {
    box-shadow: none;
    border: 2px solid #27588a;
}
.top-header .btn-primary:not(:disabled):not(.disabled):active {
    background-color: transparent !important;
    border-color:transparent !important;
}
.top-header.btn-primary.focus, .top-header .btn-primary:focus{
    box-shadow: none !important;
}
.advance-search img.filter {
    width: 17px;
    margin-right: 10px;
}

.advance-search a {
    color: #160c69;
    font-size: 16px;
    font-weight: 600;
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
    width: max-content;
    float: right;
}
.advance-search a:hover {
    text-decoration: none;
    color: #27588a;
}
.result_inner .pdf-detail:hover {
    color: #27588a;
}

ul.pagination.item li .page-link:hover, ul.pagination.item li span:hover  {
    color: #27588a !important;
}
ul.pagination.item li:hover .next_class:after {
    border-color: #27588a !important;;
}
.top-header  .top-header-inner {
    border-bottom: 1px solid #b9b7b7;
}
footer.footer {
    margin: 0;
    background-color: #ededed;
    padding: 0;
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 999;
}
footer.footer .row {
    padding: 10px 0;
}
footer.footer p {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 600;

}

footer.footer a {
    color: #b10000;
}
footer.footer a:hover {
    color: #b10000;
    text-decoration: none;
}
span.bottom-border {
    height: 7px;
    display: block;
    background-image: linear-gradient(to right,#04266b 0%, #6776de 100%);
}

@media screen and (max-width: 900px) {
.top-header .form-control {
    padding-right: 48px;
}
}

@media screen and (min-width: 557px) {
    footer.footer p img {
        margin-left: 10px;
    }
    footer.footer .footer-right-sec p {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-content: center;
        justify-content: right;
        align-items: center;
        float: right;
    }
}
@media screen and (max-width: 556px) {
    .advance-search a {
        justify-content: center;
        float: none;
        margin: 0 auto;
    }
}