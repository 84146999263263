@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
.footer {
    margin-top: 1em;
    padding: 1em;
    font-size: 0.85em;
    color: #666666;
    text-align: center;
}

div.container a.input-group-btn {
    font-size: 14px;
}

.suggestions {
    position: relative;
    display: inline-block;
    width: inherit;
    z-index:99
}

.input-group {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
}
.header {
    background-color: #0078d7;
    color: #eee;
}

.navbar-logo {
    width: 1.5em;
    height: auto;
}

.nav-link { color: #fff; }
.nav-link:hover { color: #eee; }

.auth-link { color: #fff; }
.auth-link:hover { color: #eee; }

.sui-layout-header {
    background-color: #0078d7;
    color: #eee;
}
.sui-search-box__submit {
    background: linear-gradient(rgb(60, 226, 102), rgb(34, 151, 57));
    letter-spacing: 0.1em;
}
.sui-search-box__submit:hover {
    background: linear-gradient(rgb(34, 151, 57), rgb(60, 226, 102));
}

.pager-style {
    margin-left: auto;
    margin-right: auto;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.search-bar {
    margin: 1em;
    margin-bottom: 1em;
    margin-top: 2em;
}
.home-search {
    margin: 0;
    max-width: 100%;
    display: block !important;
}
.logo {
    display:block;
    margin: auto auto 0;
}
.main--home {
    min-height: auto !important;
}
.nrc-search .input-group {
    width: 60%;
    margin: 0 auto;
}
a, input, textarea {
	transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
}
.nrc-search .form-control {
    border: 1px solid #c4c4c4;
    border-radius: 50px !important;
    height: 60px;
    padding: .375rem 1.75rem;
	transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
    padding-right: 75px;
    -webkit-filter: drop-shadow(0 0 8px rgba(102,102,102,0.16));
            filter: drop-shadow(0 0 8px rgba(102,102,102,0.16));
}

.nrc-search .suggestions{
    width: 100%;
}
.nrc-search .btn-primary:hover {
    background-color: transparent !important;
    border-color: transparent !important;
}
.nrc-search .btn-primary {
    background-color: transparent !important;
    border-color: transparent !important;
}
.nrc-search .input-group-btn {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 99;
}
.nrc-search  img.search-icon {
    width: 30px;
    height: 44px;
}
.nrc-search .input-group .form-control:focus, .nrc-search .input-group .form-control:hover {
    box-shadow: none;
    border: 2px solid #27588a;
}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #27588a !important;
    border-width: 2px;
}
.selectAdvancedField label:hover .MuiTypography-root, .selectAdvancedField label:hover .MuiSvgIcon-root {
    color: #27588a !important;
    fill: #27588a;
}
.nrc-search .btn-primary:not(:disabled):not(.disabled):active {
    background-color: transparent !important;
    border-color:transparent !important;
}
.nrc-search .btn-primary.focus, .nrc-search .btn-primary:focus{
    box-shadow: none !important;
}

.poweredby {
    text-align: center;
}
span.top-border {
    height: 7px;
    display: block;
    background-image: linear-gradient(to right,#6776de 0%,#04266b 100%);
}
.home-search.nrc-search {
    padding: 70px 0;
}
.top-header .suggestions{
    width: 100%;
}
.top-header .input-group {
    width: 80%;
    margin: 0 auto;
}
.top-header .form-control {
    border: 1px solid #c4c4c4;
    border-radius: 50px !important;
    height: 60px;
	transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
    padding: .375rem 1.75rem;
    padding-right: 70px;
    -webkit-filter: drop-shadow(0 0 8px rgba(102,102,102,0.16));
            filter: drop-shadow(0 0 8px rgba(102,102,102,0.16));
}
.top-header .btn-primary:hover {
    background-color: transparent !important;
    border-color: transparent !important;
}
.top-header .btn-primary {
    background-color: transparent !important;
    border-color: transparent !important;
}
.top-header .input-group-btn {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 99;
}
.top-header  img.search-icon {
    width: 30px;
    height: 44px;
}
.top-header .input-group .form-control:focus, .top-header .input-group .form-control:hover {
    box-shadow: none;
    border: 2px solid #27588a;
}
.top-header .btn-primary:not(:disabled):not(.disabled):active {
    background-color: transparent !important;
    border-color:transparent !important;
}
.top-header.btn-primary.focus, .top-header .btn-primary:focus{
    box-shadow: none !important;
}
.advance-search img.filter {
    width: 17px;
    margin-right: 10px;
}

.advance-search a {
    color: #160c69;
    font-size: 16px;
    font-weight: 600;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    width: -webkit-max-content;
    width: max-content;
    float: right;
}
.advance-search a:hover {
    text-decoration: none;
    color: #27588a;
}
.result_inner .pdf-detail:hover {
    color: #27588a;
}

ul.pagination.item li .page-link:hover, ul.pagination.item li span:hover  {
    color: #27588a !important;
}
ul.pagination.item li:hover .next_class:after {
    border-color: #27588a !important;;
}
.top-header  .top-header-inner {
    border-bottom: 1px solid #b9b7b7;
}
footer.footer {
    margin: 0;
    background-color: #ededed;
    padding: 0;
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 999;
}
footer.footer .row {
    padding: 10px 0;
}
footer.footer p {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 600;

}

footer.footer a {
    color: #b10000;
}
footer.footer a:hover {
    color: #b10000;
    text-decoration: none;
}
span.bottom-border {
    height: 7px;
    display: block;
    background-image: linear-gradient(to right,#04266b 0%, #6776de 100%);
}

@media screen and (max-width: 900px) {
.top-header .form-control {
    padding-right: 48px;
}
}

@media screen and (min-width: 557px) {
    footer.footer p img {
        margin-left: 10px;
    }
    footer.footer .footer-right-sec p {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 600;
        display: -webkit-flex;
        display: flex;
        -webkit-align-content: center;
                align-content: center;
        -webkit-justify-content: right;
                justify-content: right;
        -webkit-align-items: center;
                align-items: center;
        float: right;
    }
}
@media screen and (max-width: 556px) {
    .advance-search a {
        -webkit-justify-content: center;
                justify-content: center;
        float: none;
        margin: 0 auto;
    }
}
/* Detail Styles */

.results-info {
    margin: 0;
    padding: 30px 0;
    color: #666666;
    font-size: 16px;
    font-weight: normal;
}
.results {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    margin: auto;
    margin-left: 0em;
    margin-right: 0em;
}
span.document_pdf_link {
    cursor: pointer;
}
span.document_pdf_link:hover {
    color: #0a024a;
}
.results .result {
    width: 10rem;
    max-height: 18rem;
}
/*=============*/
.result_not_found {
    text-align: center;
    padding-top: 100px;
}

p.results-info span {
    display: block;
}

p.results-info span.not_topheading {
    color: #0a0248;
    font-size: 24px;
    letter-spacing: 1px;
    line-height: normal;
    padding-bottom: 9px;
}

p.results-info span.not_botton_heading {
    color: #666666;
    font-weight: 300;
    font-size: 18px;
    line-height: normal;
}

.result-logo {
    padding-bottom: 20px;
}


/*=========*/
.result {
    width: 250px;
    padding: 16px;
    text-align: center;
    border: 1px solid #eee;
    box-shadow: 0 2px 3px #ccc;
    margin: 10px;
    margin-bottom: 3px;
    padding-bottom: 3px;
    box-sizing: border-box;
    cursor: pointer;
}

.result:hover,
.result:active {
    background-color: #C0DDF5;
}



h2.MuiTypography-root.MuiTypography-h6 {
    text-align: center;
}

.document-body {
    width: 100%;
    background: #ededed;
    padding: 15px;
    border-radius: 5px;
    height: 300px;
    overflow-y: scroll;
    color: #333333;
    margin: 10px 0;
    width: 532px;
}

.popup-content-item-outer h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
}

.popup-content-item-outer {
    font-size: 14px;
    margin-bottom: 0;
}

.popup-content-item-outer {
    margin-bottom: 15px;
}
.document-body-items p {
    margin-bottom: 15px;
}
.loader-outer {
    padding: 15% 0;
    background: #ffffffb5;
    z-index: 9;
    height: 100%;
}

.footer-right-sec p a {
    margin-left: 10px;
}

.footer-right-sec p {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 15px 0;
}
.item {
    margin: 1em auto;
}
ul.pagination.item li .page-link {
    color: #666666 !important;
    font-size: 18px;
}
ul.pagination.item li.page-item.active .page-link {
    color: #0a024a !important;
} 
.pager {
    margin: 0;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    padding-left: 10px;
}

/* ===================== */
ul.pagination.item .next_class {
    position: relative;
}
ul.pagination.item .next_class:after {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    border-right: 2px solid #3e3e3e;
    border-top: 2px solid #3e3e3e;
    -webkit-transform: rotate( 
45deg);
            transform: rotate( 
45deg);
    top: -2px;
    right: 5px;
}   
ul.pagination.item li .preiview_class {
    position: relative;
    width: 0px !important;
    height: 37px;
    border: 0;
    padding: 0;
}
ul.pagination.item li .preiview_class:after {
    content: "";
    position: absolute;
    border-left: 2px solid #666666;
    border-top: 2px solid #666;
    width: 8px;
    height: 8px;
    left: -10px;
    top: 15px;
    -webkit-transform: rotate( 
-42deg);
            transform: rotate( 
-42deg);
} 

ul.pagination.item button.next_class {
    border: 0;
    outline: none;
}
ul.pagination.item li button:after {
    border-color: #666666 !important;
}
ul.pagination.item li .page-link {
    border: 0;
}
ul.pagination.item li.page-item.active .page-link {
    background: transparent;
    font-weight: bold;
}

ul.pagination.item li span:hover {
    background: transparent;
}
ul.pagination.item li .page-link:hover {
    background: transparent;
}
ul.pagination.item {
    margin: 70px 0;
}
.main--details {
    padding-top: 2em;
};

.image {
    width: 10em;
    height: auto;
}

.sui-layout-header {
    background-color: #0078d7;
    color: #eee;
}
.sui-search-box__submit {
    background: linear-gradient(rgb(60, 226, 102), rgb(34, 151, 57));
    letter-spacing: 0.1em;
}
.sui-search-box__submit:hover {
    background: linear-gradient(rgb(34, 151, 57), rgb(60, 226, 102));
}

.pager-style {
    margin-left: auto;
    margin-right: auto;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.search-bar {
    margin: 1em;
    margin-bottom: 1em;
    margin-top: 2em;
}

.select-input .MuiFormControl-root {
    width: 100%;
}
.select-input .MuiFormControl-root .MuiInputBase-root {
    width: 100%;
}
.select-input .MuiInputLabel-outlined.MuiInputLabel-shrink {
    -webkit-transform: translate(14px, -6px) scale(0.75);
            transform: translate(14px, -6px) scale(0.75);
    opacity: 0;
}
.height-window {
    height: 190px;
}
.FindDocumentsInput .MuiFormControl-root {
    width: 100%;
}

h5{
    color:#0a024a;
    font-weight: 600;
}
button.MuiButtonBase-root {
    width: 200px;
    border-radius: 50px;
}
.selectAdvancedField label {
    margin-bottom: 0;
}
.MuiFormControlLabel-root .PrivateSwitchBase-root-1 {
    padding: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    margin-right: 5px;
}
h2.advance-search {
    font-size: 30px;
    color: #666666;
}
.selectAdvancedField h5 {
    font-size: 21px;
    color: #0a024a;
}
h5.find-document {
    font-size: 21px;
    color: #0a024a;
}
.FindDocumentsInput .col-lg-4.text-right.pt-3 {
    font-size: 16px;
    color: #333;
    padding-top: 10px !important;
}
.document-date-sec{
    font-size: 16px;
    padding-top: 10px !important;
}
 
.MuiOutlinedInput-input {
    padding: 12.5px 14px !important;
}
.select-input label {
    line-height: 6px !important;
    font-size: 16px;
    color: #333333;
}
button.search-btn{
    width: 280px;
    padding: 7px;
    background-color: #0a024a;
    text-transform: capitalize;
    font-size: 16px;
}
.MuiInputBase-input.MuiOutlinedInput-input { 
    font-size: 16px;
    color: #333333;
}
span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    font-size: 16px;
    font-weight: normal !important;
    font-family: 'Open Sans', sans-serif;
    color: #333;
}
#Adsearch .MuiSelect-outlined.MuiSelect-outlined { 
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
}
.advance-search-page button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.search-btn:hover {
    outline: none;
    border: none;
    box-shadow: none;
}
span.ad-date-reset-btn {
    position: absolute;
    top: 9px;
    right: 42px;
    cursor: pointer;
}

span.ad-date-reset-btn.ex-space {
    margin-right: 15px;
}
.selectAdvancedField h5.adv-check-title label span.MuiFormControlLabel-label {
    font-size: 21px !important;
    color: #0a024a !important;
}
button.search-btn[type="button"] {
    background: #ccc;
    color: #000;
    margin-left: 15px;
    transition: all 0.5s;
    outline: none;
}
button.search-btn[type="button"]:hover {
    background:#303f9f;
    color: #fff;
 }


.advanced-search-popup .MuiDialog-paperWidthSm {
    max-width: 1160px;
}
.advanced-search-popup .MuiTypography-h6 {
    font-size: 1.65rem;
}
.advanced-search-popup .selectAdvancedField h5.adv-check-title label span.MuiFormControlLabel-label {
    font-size: 19px !important;
}
.advanced-search-popup .selectAdvancedField h5 {
    font-size: 19px;
    margin-bottom: 0;
    font-weight: 500 !important;
}
.advanced-search-popup .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    font-size: 15px;
}
.advanced-search-popup .PrivateSwitchBase-root-5 {
    padding: 5px;
}
.advanced-search-popup .MuiInputBase-input.MuiOutlinedInput-input {
    font-size: 14px;
    color: #333333;
    padding: 10px 10px !important;
    line-height: 16px;
    font-weight: normal;
}
.advanced-search-popup  button.search-btn[type="button"] {
    background: #0a024a;
    color: #fff;
}
.ad-search-opt-btn.btn.btn-info {
    padding: 7px 20px;
    background-color: #0a024a;
    text-transform: capitalize;
    font-size: 16px;
    border-color: #0a024a;
    border-radius: 50px;
}
.ad-search-opt-btn.btn.btn-info:hover {
    background-color: #303f9f;
    border-color: #303f9f;
}


@media screen and (max-width: 1024px) {
    span.ad-date-reset-btn {
        top: 10px;
        right: 50px;

    }
    span.ad-date-reset-btn.ex-space {
        margin-right: 15px;
    }
  }
  @media screen and (max-width: 991px) {
    h5.find-document.my-5 {
        margin: 30px 0 !important;
    }
    .FindDocumentsInput .col-lg-4.text-right.pt-3 {
        text-align: left !important;
        padding-top: 0 !important;
        margin-bottom: 10px;
    }
    h2.advance-search {
        font-size: 30px;
        margin: 30px 0 !important;
    }
    .MuiFormControlLabel-root .PrivateSwitchBase-root-1 {
        margin-left: 4px;
    }
    .col-sm-6.select-input {
        padding-left: 0;
    }
    .select-extracted-field, .select-input {
        margin-top: 20px;
    }
    .text-right.document-date-sec {
        text-align: left !important;
        margin-bottom: 15px;
    }
    .row.mt-5.search-row {
        margin-top: 30px !important;
        margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    .selectAdvancedField h5 {
        font-size: 20px;
        color: #0a024a;
    }
    h5.find-document.my-5 {
        margin: 20px 0 !important;
        font-size: 20px;
    }
    span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
        font-size: 16px;
    }
    .FindDocumentsInput .col-lg-4.text-right.pt-3 {
        margin-bottom: 10px;
        font-size: 16px;
    }
    .text-right.document-date-sec {
        margin-bottom: 10px;
        font-size: 16px;
    }
    .MuiInputBase-input.MuiOutlinedInput-input {
        font-size: 16px;
        color: #333333;
    }
    .select-input label {
        font-size: 16px;
    }
    h2.advance-search {
        font-size: 25px;
        margin: 20px 0 !important;
    }
    span.document_pdf_link {
        cursor: pointer;
        display: -webkit-flex;
        display: flex;
        white-space: pre-wrap;
        word-break: break-all;
        -webkit-align-items: flex-start;
                align-items: flex-start;
    }
    span.document_pdf_link img {
        margin-top: 3px;
    }
}
@media screen and (max-width: 580px) {
    .col-sm-6.select-input {
        padding-left: 15px;
    }
    .row.mb-3.FindDocumentsInput {
        margin-bottom: 10px !important;
    }
    .extra-section {
        margin: 10px 0 !important;
    }
    button.search-btn {
        width: 200px;
    }
    .row.mt-5.search-row {
        margin-bottom: 40px !important;
    }
    .selectAdvancedField .row-cols-2>* {
        max-width: 100%;
        -webkit-flex: 0 0 100%;
                flex: 0 0 100%;
    }
    span.ad-date-reset-btn {
        top: 10px;
        right: 60px;

    }
    span.ad-date-reset-btn.ex-space {
        margin-right: 0;
    }
    button.search-btn[type="button"] {
        margin-top: 15px;
        margin-left: 0;
    }
    .ad-find-search .col-sm-6 {
        text-align: center !important;
        margin-bottom: 15px;
    }
}

/* Application styles */
.app {
    background-color: #fff;
    padding: 0;
    min-width: 375px;
}
body {
    font-family: 'Open Sans', sans-serif;
}
.main--home {
    min-height: 40em;
}
.main--details {
    min-height: 40em;
}
.relevance_score ul {
    padding: 0;
    margin: 0;
}
.relevance_score ul li:first-child {
    padding-right: 10px;
}
.relevance_score ul li {
    list-style: none;
    display: inline-block;
}
.relevance_score ul li:first-child::after {
    content: '|';
    margin-left: 10px;
}
.document-listing h4 {
    font-size: 24px;
    color: #0a024a;
    font-weight: normal;
}

.document-listing a {
    color: #333333;
}
.document-listing a:hover{
    text-decoration: none;
}
.document-listing  a.pdf-detail{
    color: #0a024a;
}
img.document-pdf {
    margin-top: -3px;
}
.col-xs-12.col-sm-12.mb-5.result_inner:last-child {
    margin-bottom: 0 !important;
}
.container-fluid.app {
    min-height: 767px;
    position: relative;
}
.loader-outer  {
    padding: 15% 0;
}
nav.pager {
    margin-bottom: 200px;
}

.result_inner h5 {
    font-size: 1.25rem;
    line-height: 26px;
    color: #0a024a;
    font-weight: 600;
    font-family: "Open Sans";
}

.result_inner h5 span:hover {
    color: #27588a;
}
.result_inner a {
    color: #333;
    text-decoration: none;
}
.result_inner a:hover {
    color: #0a024a;
}
.result_inner .pdf-detail {
    cursor: pointer;
}
.popup-content-item-outer h4 {
    color: #0a024a;
    font-weight: 400;
}
.document-body {
    word-break: break-all;
    white-space: pre-wrap
}
h2.MuiTypography-root.MuiTypography-h6 span {
    cursor: pointer;
}
h2.MuiTypography-root.MuiTypography-h6 {
    color: #666766;
}

.advance-search-page .advance-search.text-sm-right.text-center {
    display: none;
}
.mark, mark {
    background-color: yellow;
    padding: 0 2px;
}

a, input, textarea {
	transition: all 150ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
}

ul.pagination li span {
    display: block;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
}

ul.pagination li span::after {
    top: 15px !important;
}
ul.pagination.item .next_class:after {
    right: -5px;
}

ul.pagination.item li .preiview_class:after {
    left: -5px;
}

ul.pagination.item button.next_class {
    background: #ffff;
}

@media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1200px !important;
    }
}

@media (max-width: 1024px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 100% !important;
    }
}



@media (max-width:900px){
    .navbar-brand img {
        width: 100%;
    }
    .top-header .suggestions + .input-group-btn button {
        padding: 0;
        height: 38px;
    }
    .top-header img.search-icon {
        width: 30px;
        height: 20px;
    }
    .top-header .form-control {
        height: 40px;
    }
    .top-header .input-group-btn {
        margin-left: -1px;
    }
    .top-header .input-group {
        width: 100%;
        margin: 0 auto;
    }
    
}
@media (min-width: 768px) and (max-width:900px) {
    .container-fluid.app {
        min-height: 100vh;
        position: relative;
    }
}
@media (min-width: 576px) and (max-width:767px) {
    .advance-search a {
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-align-items: center;
                align-items: center;
    }
    .nrc-search .input-group {
        width: 90%;
        margin: 0 auto;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap;
    }
}
@media (max-width:575px) {
    .popup-content-item-outer {
        margin-bottom: 15px;
        width: 100%;
        max-width: 100%;
        -webkit-flex: 100% 1;
                flex: 100% 1;
        word-break: break-all;
        white-space: pre-wrap;
    }
    .document-body {
        width: 100%;
    }
    .result_inner a {
        word-break: break-all;
    }
    .top-header .input-group {
        width: -webkit-max-content;
        width: max-content;
        margin: 5px auto;
        padding-left: 4px;
    }
    .advance-search.text-sm-right.text-center {
        margin-bottom: 20px;
        margin-top: 5px;
    }
    .row.home-search.nrc-search.m-0 img.logo.pb-5 {
        width: 200px;
    }
    .nrc-search .input-group {
        width: 90%;
        margin: 0 auto;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: nowrap;
                flex-wrap: nowrap;
    }
    .result_inner h4 {
        font-size: 18px;
        line-height: 26px;
    }
    body {
        font-size: 14px;
    }
}

@media (min-width:1600px) {
    .container-fluid.app {
        min-height: 100vh;
    }
}
